.profile-selection {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover !important;
  background: url("../../assets/image/login_background.jpg");
  .user-svg-icon {
    position: relative;
    left: 6%;
    height: 70%;
    width: 80%;
  }
  h2 {
    margin-bottom: 2rem;
  }

  .profiles {
    display: flex;
    justify-content: center;

    .profile-card {
      width: 200px;
      height: 250px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin: 0 1rem;
      cursor: pointer;
      overflow: hidden;
      transition: transform 0.3s;
      color: #e6f4ff;
      background: #e6f4ff;

      &:hover {
        transform: translateY(-5px);
      }
      &.selected {
        border: 2px solid #003366;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s, box-shadow 0.3s;
      }

      .profile-image {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
      }

      .profile-name {
        margin-top: 1rem;
        color: #003366;
      }
    }
  }
}
