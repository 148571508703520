.pms-switch {
}

.commmon-use-on-switch {
  margin-bottom: 0;
  .ant-form-item-row {
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-switch {
            height: 31px;
            min-width: 50px;
            background-color: #fff;
            color: #fff;
            .ant-click-animating-node {
              display: none;
            }
            &:focus {
              box-shadow: unset !important;
              outline: unset !important;
            }
            border: 2px solid #2712ff;
            .ant-switch-handle {
              top: 4px;
            }
            .ant-switch-handle::before {
              background-color: #2712ff;
              margin-top: 1px;
            }
          }
          .ant-switch-checked {
            background: transparent;
            background-color: #2712ff;
            .ant-switch-handle::before {
              background-color: #f3f4ff;
            }
          }
        }
      }
    }
  }
}
