@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;1,100&display=swap");
* {
  font-family: "Mulish", sans-serif !important;
}
body {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
}
h1,
h2.h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-family: "Mulish", sans-serif !important;
}

h4 {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}
input {
  width: 100%;
}
h3 {
  font-size: 23px !important;
  margin-top: 5px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.select-category {
  width: 100%;
  margin-top: 10px;
}

.sidebar-bg .ant-layout-sider-children,
.sidebar-bg .ant-menu {
  background: #ffffff !important;
  padding: 5px;

  /* border-right: 1px solid rgb(237, 235, 235) !important; */
}
.sidebar-bg .ant-menu {
  color: rgb(0, 0, 0) !important;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-menu-light
  .ant-menu-item-selected {
  color: #003366 !important;
}
.ant-layout-content {
  background-color: #edf5ff;
  border-radius: 27px 0 0 0;
}
::-webkit-scrollbar {
  width: 0px;
}
.ant-layout {
  background-color: #fff !important;
}

.btn {
  border-radius: 0 !important;
  outline: none !important;
  border: none !important;
}

.main-content-area {
  overflow-y: auto;
  overflow-x: hidden;
}
.footer-message {
  display: flex;
  justify-content: center;
  h1 {
    font-size: 0.8rem;
    color: red;
    padding: 10px;
  }
}

h3.section-title {
  font-size: 20px !important;
  font-family: "Mulish", sans-serif !important;
}
.ant-menu {
  border-inline-end: 0 !important;
}

.ant-space-item {
  width: 100% !important;
}
.ant-select-in-form-item {
  width: 100% !important;
}

.ant-space .ant-space-item:nth-child(2) {
  width: 20px !important;
}

h3 {
  font-size: 17px !important;
  font-weight: bold !important;
  color: #003366;
}
.ant-checkbox-wrapper {
  font-size: 17px !important;
  font-weight: 800;
  margin: 10px;
  color: #003366;
}
.ant-collapse {
  margin-top: 25px;
  display: block;
  border: 0;
}

.btn-msl {
  width: 95% !important;
  display: block;
  margin: auto !important;
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.bg-white {
  background-color: #fff !important;
  padding: 0 0 10px 0 !important;
}

.bg-whi-2 {
  background-color: #fff !important;
}

.ant-table-cell .ant-btn {
  color: #e1b505 !important;
  border: 1px solid #e1b505 !important;
  background-color: #fff8de !important;
  font-weight: 700;
  font-size: 12px !important;
}

.ant-table-cell .ant-btn:hover {
  color: #e1b505 !important;
}

.btn-reject {
  background-color: #ffebed !important;
  color: #fc8d96 !important;
  border: 1px solid #fc8d96 !important;
}

.yellow {
  background-color: #fff8de;
  color: #e1b505 !important;
  border: 1px solid #e1b505;
}

.yellow:hover {
  background-color: #fff8de;
  color: #e1b505 !important;
  border: 1px solid #e1b505;
}

.btn-success {
  color: green !important;
  background-color: #e5f9f6 !important;
  border: 1px solid green;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #e6f4ff !important;
  color: #003366 !important;
  border: 1px solid #003366;
}

.project-heading {
  text-transform: capitalize !important;
}

.pss {
  font-size: 14px !important;
  margin-right: 10px !important;
}
.header-avatar {
  .ant-avatar-circle {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #edf5ff !important;
    color: #003366;
    border: 1px solid #003366;
  }
  .ant-avatar {
    border-radius: 10px !important;
  }
}

.btn-basic {
  background-color: #e6f4ff;
  color: #003366 !important;
}

.jus-spac-ar {
  justify-items: stretch;
}

.ant-table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
}
td {
  max-width: 20px;
}
.ant-table-cell .ant-space .ant-space-item .ant-tag {
  font-size: 8px;
}

.mg-25 {
  padding-left: 25px !important;
}

.bg-whi {
  background-color: #fff;
  padding-top: 15px;
  border-radius: 10px;
}
.ant-collapse-header {
  padding: 16px !important;
  margin-bottom: 13px;

  border-radius: 10px;
  background-color: #ffffff;
}

.margin-left {
  margin-left: 15px !important;
}

.row .c1:nth-child(1) {
  background-color: #fffbcd !important;
}
.row .c1:nth-child(2) {
  background-color: #baedbd !important;
}
.row .c1:nth-child(3) {
  background-color: #ffebdf !important;
}
.row .c1:nth-child(4) {
  background-color: #ffc9c9 !important;
}
.row .c1:nth-child(5) {
  background-color: #b1e3ff !important;
}
.row .c1:nth-child(6) {
  background-color: #ccd3f9 !important;
}

.row .c1:nth-child(7) {
  background-color: #d5f8d7 !important;
}

.row .c1:nth-child(8) {
  background-color: #fffbcd !important;
}
