.charts{
 
    border-radius: 13px;
  
    background-color: white;
    width:48%;
    margin: 10px 1% 10px 1%;
    padding: 20px 25px;
}

.charts2{
   
    border-radius: 13px;
    padding: 20px 25px;
    background-color: white;
    width:98%;
    margin: 10px 1% 0 1%;
}
.dashboard-heading{
font-weight: 800;
font-size: 23px;
margin-top: 10px;
color: #1e2f55 !important;
}
.welcome{
    margin-top: 5px;
    font-size: 16px;
}
.margin-btm{
    margin-bottom: 0 !important;
}

.count{
    width: 23% !important;
    margin: 10px 1% 10px 1%;
    border-radius: 10px;
    padding: 22px;

}
@media (max-width: 800px)  {
    .count {
     width: 50% !important;
  }
  }
@media (max-width: 500px)  {
    .count {
     width: 100% !important;
  }
  .dashboard{
      margin-left: 15px;
  }
  }

.count span{
    font-size: 30px !important;
    font-weight: 700;
    font-family: 'Roboto', sans-serif !important;
    color: rgb(57, 57, 57) !important;

}
.count h5{
    font-size: 17px;
    font-weight: 600;
    margin-top: 10px !important;
    color: rgb(27, 27, 27) !important;
}

/* .c1{
    background-image: linear-gradient(rgb(255, 255, 255), #d6dafb);
 
} */
.c2{
    background-image: linear-gradient(rgb(255, 255, 255), #d8f9ed);
 
}
.c3{
    background-image: linear-gradient(rgb(255, 255, 255), #efd8e2);


}
.c4{
    background-image: linear-gradient(rgb(255, 255, 255), #f9f3d3);
  

}