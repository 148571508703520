.project-view {
  border-radius: 8px;
  &__top-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
    gap: 15px;
  }
  .remark-download-container {
    display: flex;
    gap: 8px;
  }
  &__warning-container {
    flex: 1;
    .message-box {
      height: 42px;
    }
  }
  .general-info {
    &__stakeholder-list,
    &__owner-list {
      display: inline-flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
  .hold-button-warper-mobile {
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .hold-button-warper-web {
    font-size: 0.8rem;
    text-align: start;
    margin-bottom: 10px;
  }
  .forward-button-mobile {
    font-size: 0.8rem;
    margin-top: 10px;
  }
  .forward-button-web {
    text-align: start;
  }
}

.totalAmount {
  color: #003366;
  /* border: 1px solid black; */

  font-weight: 900 !important;

  font-size: 20px;
}
.text-blue {
  color: #003366 !important;
  margin-bottom: 25px !important;
}
.titleView {
  color: #003366;
  padding: 15px 30px;

  font-weight: bold;
  font-size: 15px;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps.ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-content {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  width: 611px;
  margin: 8px;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps .ant-steps-item-icon {
  margin-top: 25px;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  margin-top: 32px;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: #01b546;
}

:where(.css-sk7ap8).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #01b546;
  border-color: #e6f4ff;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
  width: 611px;
  margin: 8px;
}
/* :where(.css-sk7ap8).ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-content {
        display: block;
        min-height: 48px;
        overflow: hidden;
        border: 1px solid gray;
        padding: 10px;
        border-radius: 10px;
        width: 611px;
        margin: 8px;
    } */

:where(.css-sk7ap8).ant-steps.ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-icon {
  float: left;
  margin-inline-end: 16px;
  margin-top: 25px;
}

:where(.css-sk7ap8).ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  inset-inline-start: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
  margin-top: 32px;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-modal .ant-modal-content {
  overflow: auto;
  width: 100%;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-modal .ant-modal-body {
  overflow: auto;
  /* // width: 134%; */
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps
  .ant-steps-item-subtitle {
  margin-left: 20px;
  /* width: 100%;
    display: flex;
    justify-content: right; */
  text-align: right;
}

.statusContainer {
  width: 39%;
  text-align: right;
  margin-right: 8px;
}
.statusTag {
  padding: 6px 7px;
  font-size: 14px;
  font-weight: 500;
}

.ant-collapse-header {
  padding: 0;
}
.ant-steps-icon {
  color: rgb(253, 253, 253) !important;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps
  .ant-steps-item-icon
  .ant-steps-icon {
  top: -4.5px !important;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: rgb(176, 176, 176);
}

.tab-brd .ant-row .ant-col {
  border: 1px solid rgb(237, 237, 237);
  padding: 10px 15px;
}
.tab-brd {
  margin-bottom: 0 !important;
  margin-left: 4px !important;
}
.tab-brd .ant-row .ant-col p {
  margin-bottom: 0 !important;
}

.red {
  color: red;
}
.tab-brd-1 {
  margin-left: 6px !important;
}

.margin-btm .ant-col .ant-row .ant-col {
  padding: 10px 15px;
  border: 1px solid rgb(237, 237, 237);
}

.merg {
  margin-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.margin-top {
  margin-top: 15px !important;
}

.split-btn {
  padding-top: 0 !important;
  float: right;
}

.form-wrapper {
  padding: 25px !important;
  margin-bottom: 13px;
  background-color: #fff;
  border-radius: 10px;
}
.owner-stakeholder-label-wrapper {
  display: flex;
  align-items: center;
}

.depa-btn {
  background-color: #e6f4ff;
  color: #003366 !important;
  border: 1px solid #003366;
  height: 27px;
  padding: 3px 10px !important;
  font-size: 12px;
  margin-left: 20px;
}

.split {
  padding: 0 29px !important;
}
.split1 {
  padding: 0 40px !important;
}

.h-40 {
  height: 40px;
  margin-left: 3px !important;
}

.change {
  font-size: 12px;
  color: red;
  width: 450px;
  margin-top: 10px;
  padding: 3px;
}
.departmentSection {
  margin: 10px 0 10px -4px;
}

@media (max-width: 500px) {
  .hold {
    height: 33px;
  }

  .statusContainer {
    display: none;
  }

  .project-view {
    &__warning-container {
      margin-right: 56px;

      .message-box {
        height: 32px;
        font-size: 14px;
      }
    }
  }

  .common-button {
    padding: 0 9px;
    height: 30px;
    font-size: 13px;
  }

  .departmentSection {
    margin: 10px 0 10px -4px;
  }

  .change {
    font-size: 10px;
  }
  .ant-modal .ant-modal-content {
    padding-bottom: 10px;
    overflow: auto !important;
    width: 100% !important;
  }
  .ant-modal-body {
    overflow: auto !important;
  }
  :where(.css-dev-only-do-not-override-sk7ap8).ant-modal .ant-modal-footer {
    text-align: center;
  }
  .split1 {
    padding: 0 16px !important;
  }
  .status + .ant-tag {
    font-size: 14px;
    padding: 3px 9px 5px 7px;
  }
  .status-card {
    padding: 6px 9px 8px 9px;
  }

  .titleView {
    padding: 11px 17px;
    font-size: 13px;
  }
  .ant-collapse-header {
    padding: 9px 13px !important;
    font-size: 13px;
  }

  .tag {
    min-height: 34px;
  }
  .pro-id {
    padding: 4px 4px;
    font-size: 0.7rem;
  }
  .split {
    padding: 0 14px !important;
    font-size: 0.8rem;
  }
  .depa-btn {
    margin-left: 30px;
    padding: 4px !important;
    font-size: 0.7rem !important;
    margin-top: 5px;
    height: 17% !important;
  }
  .projectName {
    font-size: 0.9rem !important;
  }

  .ant-btn.ant-btn-sm {
    font-size: 12px !important;
  }

  .bg-white {
    padding: 0px 0 0px 9px !important;
  }

  .ant-select-selector {
    height: 35px !important;
    padding: 2px 11px !important;
    font-size: 0.8rem !important;
  }

  .form-wrapper {
    padding: 8px !important;
  }

  .btn button {
    height: 30px;
    padding: 0px 13px;
  }
  .pss {
    font-size: 0.8rem !important;
  }
  .phoneTitle {
    font-size: 0.8rem;
  }
  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 10px;
    width: auto;
    margin: 8px;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
    font-size: 0.9rem;
  }
  .userName {
    font-size: 0.8rem;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    overflow: auto;
  }
  /* .ant-collapse-borderless >.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    width: 577px;
    overflow: auto;
} */
  .ant-btn.ant-btn-lg {
    font-size: 0.8rem;
    height: 30px;
  }
  .status {
    font-size: 1rem !important;
  }
  /* .ant-modal .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
 
    font-size: 0.8rem;
    width: 44%;
    
    height: 35px;
} */
}
.projectName {
  margin-left: 16px;
  margin-bottom: 5px;
}
.yellow {
  background-color: #fff8de;
  color: #e1b505 !important;
  border: 1px solid #e1b505;
}

.yellow:hover {
  background-color: #fff8de;
  color: #e1b505 !important;
  border: 1px solid #e1b505;
}
