@import "../../../assets/scss/mixins";
.commonSelect {
  &:not(.ant-select-multiple) {
    .ant-select-selector {
      .ant-select-selector {
        height: 35px !important;
        padding: 2px 11px !important;
      }
      @include md {
        height: 45px !important;
        padding: 5px 11px !important;
      }
    }
  }
  .ant-select-selector {
    .ant-select-selection-placeholder {
      line-height: 30px !important;
    }
    .ant-select-selection-item {
      line-height: 30px !important;
    }
  }
}
