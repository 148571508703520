.tag-cnt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag {
  display: flex;
  max-width: 100%;
  align-items: center;
  margin: 8px 8px 8px 0px !important;
  font-size: 14px !important;
  min-height: 42px;
}

.chip {
  color: white;
  font-weight: 400;
}

.dot {
  content: " ";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  &.inactive {
    background-color: #fff;
  }
  &.active {
    background-color: #000;
  }
  height: 5px;
  width: 5px;
  border-radius: 50px;
}
