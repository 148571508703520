.view-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #1890ff;
}
.project-view-list {
  .owner-color {
    background-color: #c9e4ca;
    .ant-table-cell {
      background-color: #c9e4ca;
    }
    .ant-table-cell-row-hover {
      background-color: #dae6da !important;
    }
  }
  .stack-color {
    background-color: #fdd6b5;
    .ant-table-cell {
      background-color: #fdd6b5;
    }
    .ant-table-cell-row-hover {
      background-color: #f9e1cd !important;
    }
  }
  .owner-stack-color {
    background-color: #fff;
    .ant-table-cell {
      background-color: #fff;
    }
    // .ant-table-cell-row-hover {
    //   background-color: #e5e2e2 !important;
    // }
  }
  .ant-table
    .ant-table-container
    .ant-table-content
    table
    thead.ant-table-thead
    .ant-table-cell {
    background-color: #fcfdff;
    color: #002447;
    font-weight: 800;
  }
  .ant-table-column-sorter {
    // color: #fff !important;
    &:hover {
      // color: #fff !important;
    }
  }

  .button {
    display: none;
  }
  .button2 {
    display: block;
  }
}

.badge-status {
  .ant-badge-status-text {
    font-size: 12px !important;
  }
  .ant-badge-status-dot {
    width: 10px !important;
    height: 10px !important;
  }
}

@media (max-width: 800px) {
  .project-view-list {
    .ant-table
      .ant-table-container
      .ant-table-content
      table
      thead.ant-table-thead
      .ant-table-cell {
      background-color: #fcfdff;
      color: #002447;
      font-weight: 800;
      width: 34%;
      font-size: 0.8rem;
    }
  }

  .search input {
    height: 36px !important;
    font-size: 0.9rem;
  }

  .ant-badge {
    .ant-badge-status-text {
      font-size: 12px !important;
    }
  }

  .badge-status {
    .ant-badge-status-dot {
      width: 8px !important;
      height: 8px !important;
    }
  }
  .ant-table-wrapper
    .ant-table:not(.ant-table-bordered)
    .ant-table-tbody
    > tr
    > td {
    font-size: 0.8rem;
  }
}

.create-search {
  display: flex;
  .select-stage-wrapper {
    margin-right: 10px;
    .ant-form-item {
      .select-category {
        margin: 0;
      }
    }
  }
  .search {
    margin: auto 0 auto auto;
  }
  .btn {
    margin-left: 0;
  }
}

.indicator-container {
  display: flex;
  gap: 16px;
  justify-content: end;
  .ant-badge .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
}
