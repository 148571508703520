.main-spinner {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  z-index: 9999;
  .ant-spin {
    color: #2711ff !important;
  }
}
.loader::before {
  content: " ";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.2;
}
.loader-funds::before {
  content: " ";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.2;
}
.loader-cards::before {
  content: " ";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.2;
}
.loader-setup::before {
  content: " ";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.2;
}
