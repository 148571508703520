#auth_login {
  position: relative;
}
#normal_login {
  width: 400px;
  position: relative;
  .btnItem {
    margin-bottom: unset !important;
  }
  .textOr {
    text-align: center;
    font-weight: bolder;
    margin: 8px 0px;
  }
}
.form-custom {
  height: 300px;
  width: 400px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 20px;

  .login-logo {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .google-btn {
    background: #ffffff;
    border-radius: 100px;
    outline: none;
    padding: 12px;
    border: none;
    font-weight: 500;
    padding-left: 40px;
    position: relative;
    &::before {
      content: "";
      width: 48px;
      scale: 0.5;
      height: 50px;
      left: 9px;
      position: absolute;
      transform: translate(-50%, -50%);
      background-image: url("../../assets/image/logos_google-gmail.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
.login-form-container {
  width: 100%;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover !important;
  background: url("../../assets/image/login_background.jpg");
  .login-form-wrapper {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.login-btn-warpper {
  position: absolute;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  bottom: 5%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login-button {
  background: black;
  margin: 2px;
  color: #fff;
  height: 52px;
  border-radius: 100px;
  border: 1px solid #000;
  width: 150px;
  // left: 50%;
}
