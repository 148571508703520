.header-wrapper {
  background: #fff;
}
.header-wrapper .search {
  width: 50%;
  margin: auto;
}
.user-role {
  font-size: 12px;
  color: #e6f4ff;
  display: inline-block;
  background: #003366;
  padding: 2px 5px;
  border-radius: 4px;
}
.header-avatar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .user-details {
    .username {
      // width: max-content;
    }
    .user-role {
      // font-weight: 500;
    }
  }
}

.projectLogo {
  width: 62%;
  padding-left: 15px;
}
.user {
  width: 29%;
}

@media (max-width: 800px) {
  .user {
    width: 45%;
  }
  .projectLogo {
    width: 46%;
  }
}

@media (max-width: 500px) {
  .user-details {
    font-size: 0.8rem;
  }
  .user {
    width: 57%;
  }
  .projectLogo {
    width: 34%;
  }
  .avatar {
    width: 37px;
    height: 38px;
  }
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-input-search
  .ant-input-search-button {
  height: 42px;
}
@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .header-avatar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    .userIconDiv {
      display: none;
    }
  }
}

.projectLogo {
  width: 62%;
  padding-left: 15px;
}
.user {
  width: 29%;
}

@media (max-width: 800px) {
  .user {
    width: 45%;
  }
  .projectLogo {
    width: 46%;
  }
}

@media (max-width: 500px) {
  .user-details {
    font-size: 0.8rem;
  }
  .user {
    width: 57%;
  }
  .projectLogo {
    width: 34%;
  }
  .avatar {
    width: 37px;
    height: 38px;
  }
  .header-avatar .ant-avatar {
    margin-right: 8px;
  }
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-input-search
  .ant-input-search-button {
  height: 42px;
}
