@media (max-width: 800px) {
  .title {
    font-size: 0.8rem !important;
  }
  .project-title {
    width: 249px;
    padding: 6px;
  }
  .section-wrapper {
    padding: 8px;
  }

  .ant-select-single .ant-select-selector {
    font-size: 0.8rem;
  }
  .ant-input {
    font-size: 13px;
    padding: 6px;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 0.8rem;
  }
  .phoneTitle {
    font-size: 0.8rem !important;
  }
  .ant-input-number-affix-wrapper {
    height: 32px;
  }

  .projectTitle {
    font-size: 0.9rem !important;
  }
  .ant-input-number {
    padding: 5px;

    display: flex;

    align-items: center;
  }
  .ant-checkbox-wrapper {
    font-size: 16px !important;
  }
  .risk-btn {
    padding: 4px 16px;
  }
  .lbl-file {
    padding: 6px 12px;
  }
  .Total {
    padding: 11px 0px;
    // height: 41px;
  }
  // .btn button {

  //     padding: 0 33px;
  //     height: 32px;

  // }
}
