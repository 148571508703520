.chat-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding-left: 10px;
  border-radius: 5px;
}
.chat-message {
  background-color: #edf5ff;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  p {
    i {
      font-weight: 300;
    }
    // font-weight: 400;
    margin-left: 10px;
    margin-bottom: 4px;
  }
  .message-date {
    display: flex;
    justify-content: right;
    text-align: right;
  }
  .chat-person {
    color: #0071ff;
  }
}

.chat-message strong {
  font-weight: bold;
}
