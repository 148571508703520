@import "../../../assets/scss/mixins";
.common-button {
  padding: 0 8px;
  height: 30px;
  color: #e2f2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  @include sm {
    padding: 0 16px;
    height: 42px;
  }
  &.ant-btn-circle,
  &.ant-btn-circle:hover {
    width: 32px;
    height: 32px;
    color: #fff;
    background: #003366;
    border: 1px solid #003366;
    line-height: 1;
    .anticon {
      transform: scale(1);
    }
  }
  &.primary-button,
  &.primary-button:hover {
    background-color: #003366;
    border: 1px solid #003366;
    color: #e2f2ff;
  }
  &.btn-2,
  &.btn-2:hover {
    background-color: #faede4;
    color: #db763b;
    border: 1px solid #db763b;
    box-shadow: rgba(253, 140, 11, 0.2) 0px 2px 8px 0px;
  }
  &.btn-3,
  &.btn-3:hover {
    background-color: #fff8de;
    color: #e1b505 !important;
    border: 1px solid #e1b505;

    box-shadow: rgba(253, 140, 11, 0.2) 0px 2px 8px 0px;
  }
  &.btn-4,
  &.btn-4:hover {
    background-color: #ffebed !important;
    color: #fc8d96 !important;
    border: 1px solid #fc8d96 !important;
    box-shadow: rgba(253, 140, 11, 0.2) 0px 2px 8px 0px;
  }
}
