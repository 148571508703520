* {
  box-sizing: border-box;
}

body {
  font-family: source sans pro;
}
h3 {
  font-weight: 400;
  font-size: 16px;
}
p {
  font-size: 12px;
  color: #888;
}

.stage {
  max-width: 80%;
  margin: 60px 10%;
  position: relative;
}
.folder-wrap {
  display: flex;
  flex-wrap: wrap;
}
.folder-wrap::before {
  content: "Folder name";
  display: block;
  position: absolute;
  top: -40px;
}
.folder-wrap:first-child::before {
  content: "Home (top of file structure)";
  display: block;
  position: absolute;
  top: -40px;
}
.tile {
  border-radius: 3px;
  width: calc(20% - 17px);
  margin-bottom: 23px;
  text-align: center;
  border: 1px solid #eeeeee;
  transition: 0.2s all cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  padding: 35px 16px 25px;
  margin-right: 17px;
  cursor: pointer;
}
.tile:hover {
  box-shadow: 0px 7px 5px -6px rgba(0, 0, 0, 0.12);
}
.tile i {
  color: #00a8ff;
  height: 55px;
  margin-bottom: 20px;
  font-size: 55px;
  display: block;
  line-height: 54px;
  cursor: pointer;
}
.tile i.mdi-file-document {
  color: #8fd9ff;
}

.back {
  font-size: 26px;
  border-radius: 50px;
  background: #00a8ff;
  border: 0;
  color: white;
  width: 60px;
  height: 60px;
  margin: 20px 20px 0;
  outline: none;
  cursor: pointer;
}

/* Transitioning */
.folder-wrap {
  position: absolute;
  width: 100%;
  transition: 0.365s all cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  opacity: 0;
  top: 0;
}
.folder-wrap.level-up {
  transform: scale(1.2);
}
.folder-wrap.level-current {
  transform: scale(1);
  pointer-events: all;
  opacity: 1;
  position: relative;
  height: auto;
  overflow: visible;
}
.folder-wrap.level-down {
  transform: scale(0.8);
}
.card-warrper {
  margin: 10px;
  .file-card {
    // transition: transform 250ms, opacity 400ms;
    :hover {
      // transform: scale(1.2);
      // opacity: 0;
    }
  }
}
.file-list-carousal-wrapper {
  // max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background: #edf5ff;
  border-radius: 10px;
}

// @media (max-width: 500px)  {
//   .card-warrper {
//     margin: 4px;
// }
// }
