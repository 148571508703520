.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  width: 100%;
  max-width: 140px;
}
.dashboard-logo {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.ant-layout-header {
  display: flex;
  flex-direction: row;
}
.search {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 25px;
  /* margin-right: 170px; */
}
.avatar {
  display: flex;
  height: 200px;
  align-items: center;
  flex-direction: column;
  margin-left: 70px;
}
.sidebar-bg .ant-layout-sider-children,
.sidebar-bg .ant-menu {
  background: #003366;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(0, 51, 102);
}
.sidebar-bg .ant-menu {
  color: #038fdd;
  font-size: 15px;
  font-weight: 500;
}
.sidebar-bg .ant-layout-sider-children ul {
  margin-top: 10px;
}
.sidebar-bg .ant-layout-sider-children li {
  margin: 10px 5px;
}

.row {
  margin-bottom: 7px !important;
}

.ant-collapse-expand-icon {
  padding-right: 5px !important;
}
.ant-collapse-item {
  margin-bottom: 5px !important;
}

.ant-collapse-header-text {
  color: #003366 !important;
}


.ant-menu-inline .ant-menu-submenu{
  display: none;
}
.ant-collapse-content-box .row .ant-row .ant-col {
  font-weight: 400 !important;
}
/* .header{
  width: 96%;
} */
.sidebar-bg {
  .reports-submenu {
    text-transform: capitalize;
    padding-left: 35px !important;
  }
}
@media (max-width: 1200px) {
  .sider {
    background: #ffffff;
    position: absolute;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;

    overflow-x: hidden;
    overflow-y: auto;

    border-right: 1px solid #eee;
  }
}
@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .sider {
    background: #ffffff;
    position: absolute;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;

    overflow-x: hidden;
    overflow-y: auto;

    border-right: 1px solid #eee;
    margin-top: 13px;
    width: 100%;
    .sidebar-bg {
      width: 100% !important;
      min-width: 200px !important;
      max-width: 100% !important;
    }
  }
}
@media only screen and (device-width: 768px) {
  /* default iPad screens */
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}
