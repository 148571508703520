.approval-form-wrapper {
  background-color: rgb(234, 245, 255);
  height: 86vh;
  overflow: auto;
  /* padding: 10px; */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.approval-form-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  /* background-color: rgb(224, 238, 250); */
}

.section-wrapper {
  .ant-col textarea {
    margin-top: 5px;
  }
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-modal .ant-modal-body {
  overflow: auto;
  // width: 134%;
}
.box-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  .ant-custom-btn {
    margin-top: 0;
  }
}
