@import "./assets/scss/mixins";
@mixin common-button {
  margin: 0;
  padding: 0;
  list-style: none;
}

.box-view {
  white-space: nowrap;
  font-weight: bold;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  padding: 3px 10px;
  @include sm {
    padding: 5px 20px;
  }
}
.view-1 {
  background-color: rgb(255, 235, 235);
  color: red;
  border: 1px solid red;
  box-shadow: rgba(255, 0, 0, 0.25) 0px 6px 12px -2px,
    rgba(255, 0, 0, 0.3) 0px 3px 7px -3px;
}
.view-2 {
  background-color: #faede4;
  color: #db763b;
  border: 1px solid #db763b;
  box-shadow: rgba(253, 140, 11, 0.4) 0px 2px 8px 0px;
}
.m-auto {
  margin: auto;
}

.section-wrapper {
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  margin: 10px 0;
}
.section-wrapper .ant-form-item-label {
  padding-bottom: 5px;
  padding-top: 5px;
}
.gx-main-content-wrapper {
  padding: 0 0 0;
}
.title {
  margin: 0;

  margin-top: 3px;
  padding: 10px 15px;
  background-color: #edf5ff;
  border-radius: 8px;
  color: #003366 !important;
}
.ant-input {
  font-size: 16px;
  padding: 8px;
}
.ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-affix-wrapper {
  height: 43px;
}
.ant-input-number {
  width: 100%;
  padding: 5px;
}
.ant-input-number-input {
  font-size: 16px;
  padding: 8px;
}
.project-title {
  width: 275px;
  text-align: center;
  padding: 8px;
  margin: 0 auto 14px auto;
  background: #fff;
  border-radius: 16px;
}
.btn {
  margin: auto;
  display: block;
  padding: 5px 0 !important;
}

.btn button {
  display: block;
  margin: auto;
  padding: 0 16px;
  height: 42px;
  background-color: #003366 !important;
  display: flex;
  align-items: center;
  border: 1px solid #003366;
  color: #e2f2ff;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-form-item {
  margin-bottom: 5px !important;
}

.ant-col {
  padding: 0 2px;
}
.textarea {
  margin-top: 10px !important;
}
:where(
    .css-dev-only-do-not-override-sk7ap8
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 100% !important;
  margin-top: 10px !important;
}

.text-center {
  text-align: center;
}
.Total {
  background: rgb(226, 243, 255);
  padding: 13px 0;
  border-radius: 7px;
  text-align: center;
  font-weight: 900;
  margin-left: 2px !important;
  margin-right: 2px;
}

.flex-row-2 {
  max-width: 100% !important;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.flex-row-2 div {
  width: auto !important;
  flex: 1 0 0% !important;
  // align-items: center;
  display: flex;
}

.flex-row-2 div:nth-child(1) {
  justify-content: center;
}

.ant-input-group-addon .ant-btn {
  display: none !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #003366 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid #003366 !important;
  border-radius: 50% !important;
}
.ant-custom-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #003366 !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid #003366 !important;
  border-radius: 50% !important;
  margin-top: 10px;
}
.ant-input-group-addon {
  background-color: #fff !important;
  padding-left: 5px !important;
}

.search .ant-input-wrapper input {
  border-radius: 100px !important;
  display: none;
}

.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image {
  margin: 1rem 0.5rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.image-delete-button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: lightcoral;
  &:hover {
    background-color: red !important;
  }
}

img {
  padding: 0;
  margin: 0;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-form-vertical
  .ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-sk7ap8).ant-col-24.ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-sk7ap8).ant-col-xl-24.ant-form-item-label
  > label {
  margin: 0;
  color: rgb(19, 19, 19) !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-picker {
  padding: 9px 16px 9px;

  font-size: 17px;

  justify-content: center;
  align-items: center;

  width: 99%;
}

.lbl-file {
  border: 1px solid #003366;
  background-color: #003366;
  color: #fff;
  border-radius: 8px;
  padding: 8px 18px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 15px;
}

.risk-btn {
  border: 1px solid #003366;
  background-color: #003366;
  color: #fff;
  border-radius: 8px;
  padding: 8px 18px;
  cursor: pointer;
  font-weight: 500;
  margin: 5px 2px;
}

#imageSelect {
  opacity: 0;
  position: absolute;
  height: 39px;
  cursor: pointer;
}
.ant-menu-item-selected {
  background-color: #edf5ff !important;
}
.ant-table {
  margin-top: 15px !important;
}
.btn-success {
  border-radius: 8px;
  margin: auto;
  padding: 0 40px;
  height: 42px;
}
.btn-reject {
  border-radius: 8px;
  margin: auto;
  padding: 0 40px;
  height: 42px;
}

.btn-basic {
  border-radius: 8px;
  margin: auto;
  padding: 0 40px;
  height: 42px;
  border: 1px solid #003366;
  font-weight: 500;
  :hover {
    background-color: #e6f4ff !important;
    color: #003366 !important;
  }
}

// input[type="search"] {
//   height: 43px !important;
// }

.ant-menu-light .ant-menu-item-selected {
  background-color: #e6f4ff !important;
  color: #003366 !important;
}
.error-message {
  color: #ff4d4f;
}

.pro-id {
  background-color: #e2f2ff;
  color: #003366;
  border: 1px solid #003366;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  float: right;
}

.download-report {
  float: right;
}

.project-heading u {
  background: #e2f2ff;
  padding: 5px 16px;
  text-decoration: none;
  text-transform: capitalize;
  border: 1px solid #003366;
  border-radius: 5px;
}

.anticon-download {
  font-size: 17px;
}

.split-btn .btn .ant-btn {
  background-color: #faede4 !important;
  color: #db763b;
  border: 1px solid #db763b;
  font-weight: 700;
  box-shadow: rgba(253, 140, 11, 0.2) 0px 2px 8px 0px;
}
.split-btn .btn .ant-btn:hover {
  background-color: #faede4 !important;
  color: #db763b !important;
  border: 1px solid #db763b;
  font-weight: 700;
}

.split-btn-2 .btn .ant-btn {
  background-color: #faede4 !important;
  color: #db763b;
  border: 1px solid #db763b;
  font-weight: 700;
  margin-bottom: 10px;
  box-shadow: rgba(253, 140, 11, 0.4) 0px 2px 8px 0px;
  margin-left: 5px;
}
.split-btn-2 .btn .ant-btn:hover {
  background-color: #faede4 !important;
  color: #db763b !important;
  border: 1px solid #db763b;
  font-weight: 700;
}

.status-card-wrapper {
  display: flex;
}

.tag {
  background-color: #faede4 !important;
  color: #db763b;
  border: 1px solid #db763b;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 13px;
  display: inline-block;
  margin-right: 5px;
}
.status-card {
  background: #003366;
  padding: 10px 15px 15px 15px;
  border-radius: 9px;
  border: 1px solid #003366;

  width: fit-content;
}

.status {
  color: #f1f8fe !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.status + .ant-tag {
  font-size: 16px;
  font-weight: 700;
  padding: 5px 10px 8px 10px;
}

.yellow {
  background-color: #fff8de;
  color: #e1b505 !important;
  border: 1px solid #e1b505;
}

.yellow:hover {
  background-color: #fff8de;
  color: #e1b505 !important;
  border: 1px solid #e1b505;
}
.btn-reject {
  background-color: #ffebed !important;
  color: #fc8d96 !important;
  border: 1px solid #fc8d96 !important;
}

.ant-select-selection-overflow-item .ant-select-selection-item {
  margin-top: -8px;
  background: #faede4 !important;
  color: #db763b !important;
  border: 1px solid #db763b !important;
  line-height: 1;
}

.anticon-close {
  color: #db763b !important;
}
.ant-select-selection-item-remove {
  margin-top: -2px !important;
}

.pro-details {
  background-color: #fff;
  display: flex;
  padding: 10px;
  border-radius: 10px;
}

.border-radius-btm {
  border-radius: 0 0 10px 10px;
}

.border-radius-top {
  border-radius: 10px 10px 0 0;
}

.ant-modal-body strong {
  display: block;
  width: 250px;
  text-align: center;
  margin: auto;
  padding: 5px 15px;
  border-radius: 5px;
  color: #f89a2f;
  border: 1px solid #f89a2f;
  background-color: #fff4e4;
  margin-bottom: 10px;
}

.ant-modal-title {
  display: block !important;
  width: fit-content !important;
  text-align: center !important;
  margin: auto !important;
  padding: 5px 15px !important;
  border-radius: 5px;
  color: #f89a2f !important;
  border: 1px solid #f89a2f !important;
  background-color: #fff4e4 !important;
  margin-bottom: 10px !important;
}

.save-btn {
  margin-left: 25px !important;
  padding: 0 35px !important;
}

@media (max-width: 500px) {
  .flex-row-2 div {
    flex: 1 0 !important;

    display: flex;
    font-size: 0.7rem;
  }

  .sources {
    padding-top: 8px;
    padding-left: 10px;
    div {
      padding: 0 14px !important;
    }
  }
  .splitForm {
    width: 731px !important;
  }
  .text-center {
    font-size: 0.9rem;
  }
  .save-btn {
    margin-left: 0px !important;
    padding: 0 23px !important;
    font-size: 0.8rem;
  }
  .reviseForm {
    width: 450px !important;
  }
  .ant-modal .ant-modal-title {
    font-size: 13px;
    width: 281px !important;
  }
}
